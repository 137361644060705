import { parse, lightFormat, format, differenceInYears, formatDistanceToNow, intervalToDuration } from "date-fns";

const inputDateFormat = "dd.MM.yyyy";
const apiDateFormat = "yyyy-MM-dd";
const apiDateTimeFormat = "yyyy-MM-dd'T'HH:mmXXX";

export function formatDateApi(dateString: string, useTime = true) {
  const parsedDate = parse(dateString, inputDateFormat, new Date());
  // force time to be 12:00
  return format(parsedDate.setHours(12), useTime ? apiDateTimeFormat : apiDateFormat);
}

export function formatTodayApi() {
  const today = new Date();
  // set 1 minute lag for api requests
  return format(new Date().setMinutes(today.getMinutes() + 1), apiDateTimeFormat);
}

export function formatApiDateToInput(dateString: string) {
  const parsedDate = new Date(dateString);
  return lightFormat(parsedDate, inputDateFormat);
}

export function getAgeFromDateApi(dateString: string) {
  const parsedDate = new Date(dateString);
  return differenceInYears(new Date(), parsedDate);
}

export function formatDateApiToRelativeString(dateString: string) {
  const parsedDate = new Date(dateString);
  return formatDistanceToNow(parsedDate);
}

export function getDaysFromToday(dateString: string) {
  const today = new Date();
  const parsedDate = parse(dateString, inputDateFormat, new Date());
  parsedDate.setHours(12);

  const duration = intervalToDuration({ start: today, end: parsedDate });

  return duration.days + 1 || 1;
}
